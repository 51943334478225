import axios, { AxiosInstance } from 'axios';

const api: AxiosInstance = axios.create({
    baseURL: 'https://portal.orda.com.br/api/v1',
    // timeout: 5000,
    // headers: {
    //     'Content-Type': 'application/json',
    // },
});

export default api;