import React, { ChangeEvent, useState } from 'react';
import Label from '../texts/label_text';

interface CPFTextInputProps {
  textLabel: string;
  onChange: (value: string) => void;
  placeholder: string;
}

const CPFTextInput: React.FC<CPFTextInputProps> = ({ textLabel, onChange, placeholder }) => {
  const [cpf, setCpf] = useState('');

  const formatCPF = (value: string) => {
    return value
      .replace(/\D/g, '')
      .slice(0, 11)
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedCPF = formatCPF(event.target.value);
    setCpf(formattedCPF);
    onChange(formattedCPF);
  };

  return (
    <div className="sm:col-span-4">
      <Label textLabel={textLabel}/>
      <div className="mt-2">
        <div className="">
          <div className="justify-center items-center flex">
            <input 
              id="cpf"
              name="cpf"
              type="text"
              autoComplete="off"
              onChange={handleInputChange}
              value={cpf}
              placeholder={placeholder}
              className="text-neutral-500 text-sm font-medium font-['General Sans Variable'] leading-tight w-full h-11 pl-3 pr-2 py-3  bg-neutral-200 rounded-lg border border-stone-300 justify-start items-center gap-1 inline-flex"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CPFTextInput;