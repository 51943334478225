import axios, { AxiosInstance } from 'axios';
// import https from 'https';
// import fs from 'fs';

// // Configuração do cliente HTTPS com certificados
// const httpsAgent = new https.Agent({
//     cert: fs.readFileSync('path/to/client.crt'),
//     key: fs.readFileSync('path/to/client.key'),
//     ca: fs.readFileSync('path/to/ca.crt'),
// });

const api: AxiosInstance = axios.create({
    baseURL: 'https://portal.orda.com.br/api/v1',
    // httpsAgent,
    // timeout: 5000,
    // headers: {
    //     'Content-Type': 'application/json',
    // },
});

export default api;