// src/Home.tsx
import React from "react";
import BasePage from "../components/grids/page_login_base";
import MenuDrawerDefault from "../components/menu/menu_drawer_default";
import FlowFeature from "../components/flows/exemple";

import { Card } from "../components/cards/card_home";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  AlertTriangle,
  UserPlus,
  DollarSign,
  Bell,
} from "lucide-react";

const Home = () => {
  console.log("Home component rendered");
  return (
    <div className="h-screen w-screen bg-neutral-100">
      <div className="flex h-full">
        <MenuDrawerDefault />
        <div className="flex-1">
          <div className="min-h-screen bg-gray-50">
            <BasePage>
              <div className="relative overflow-hidden bg-black text-white rounded-lg m-4">
                <div className="relative z-10 p-8 md:p-12">
                  <h1 className="text-4xl font-bold mb-4">BEM VINDO À ORDA</h1>
                  <p className="text-gray-300 max-w-lg">
                    Explore infinitas possibilidades e simplifique suas tarefas
                    diárias conosco. Estamos aqui para tornar sua experiência
                    excepcional e produtiva
                  </p>
                </div>
                <div className="absolute right-0 top-0 h-full w-2/3">
                  <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-10" />
                  <img
                    src="/placeholder.svg?height=300&width=600"
                    alt="Football players"
                    className="h-full w-full object-cover"
                  />
                  <div className="absolute top-0 right-0 bg-[#e4ff0a] h-full w-1/3 flex items-center justify-center">
                    <div className="transform -rotate-90 whitespace-nowrap text-black font-bold">
                      FOOTBALL SOLUTIONS
                    </div>
                  </div>
                </div>
              </div>

              {/* Metrics Section */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
                <Card className="p-6">
                  <div className="flex items-center gap-2 text-sm text-gray-500 mb-2">
                    <DollarSign className="h-4 w-4" />
                    Patrocinios
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-2xl font-bold">71,897</span>
                    <span className="text-green-500 text-sm flex items-center">
                      <ArrowUpIcon className="h-4 w-4" />
                      16%
                    </span>
                  </div>
                </Card>

                <Card className="p-6">
                  <div className="flex items-center gap-2 text-sm text-gray-500 mb-2">
                    <UserPlus className="h-4 w-4" />
                    Novos sócios
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-2xl font-bold">71,897</span>
                    <span className="text-green-500 text-sm flex items-center">
                      <ArrowUpIcon className="h-4 w-4" />
                      16%
                    </span>
                  </div>
                </Card>

                <Card className="p-6">
                  <div className="flex items-center gap-2 text-sm text-gray-500 mb-2">
                    <DollarSign className="h-4 w-4" />
                    Despesas
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-2xl font-bold">71,897</span>
                    <span className="text-green-500 text-sm flex items-center">
                      <ArrowUpIcon className="h-4 w-4" />
                      16%
                    </span>
                  </div>
                </Card>
              </div>

              {/* Transactions Section */}
              <div className="p-4">
                <Card className="p-6">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-semibold">Movimentações</h2>
                    <button className="text-sm text-primary">Ver todas</button>
                  </div>

                  <div className="space-y-6">
                    <div>
                      <h3 className="text-sm font-medium text-gray-500 mb-3">
                        Hoje
                      </h3>
                      <div className="space-y-4">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-4">
                            <ArrowUpIcon className="h-4 w-4 text-red-500" />
                            <div>
                              <div className="font-medium">R$ 4.289,98</div>
                              <div className="text-sm text-gray-500">
                                IR - R$ 698,98
                              </div>
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="font-medium">Pagamento</div>
                            <div className="text-sm text-gray-500">
                              Fornecimento de água
                            </div>
                          </div>
                          <div className="text-sm text-gray-500">
                            Nf 0987.09866.09897
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              {/* Activity Feed */}
              <div className="fixed top-4 right-4 w-80">
                <Card className="p-4">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="font-semibold">Atividade</h2>
                    <button className="text-sm text-primary">Ver mais</button>
                  </div>
                  <div className="space-y-4">
                    <div className="flex items-center gap-3 text-sm">
                      <AlertTriangle className="h-4 w-4 text-yellow-500" />
                      <div>
                        <span className="font-medium">#Flow01</span> está em
                        atraso
                        <div className="text-gray-500">60 minutos atrás</div>
                      </div>
                    </div>
                    <div className="flex items-center gap-3 text-sm">
                      <UserPlus className="h-4 w-4 text-blue-500" />
                      <div>
                        <span>Novo patrocinador</span> adicionado
                        <div className="text-gray-500">35 minutos atrás</div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </BasePage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
