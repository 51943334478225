// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';  // Importação atualizada para React 18
import App from './routers/';
import './index.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);  // Usando createRoot ao invés de render

root.render(
  <React.StrictMode>
        <App /> 
  </React.StrictMode>
);

console.log('Root rendered');