import React from 'react'

interface Step {
  number: number
  title: string
}

interface StepperProps {
  steps: Step[]
  currentStep: number
  onStepChange: (step: number) => void
}

export default function Stepper({ steps, currentStep, onStepChange }: StepperProps) {
  return (
    <div className="w-full bg-background">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex mx-auto justify-between py-4">
          {steps.map((step) => (
            <div key={step.number} className="flex w-full flex-col">
              <div
                className={`h-1 w-full w-32 mb-4 border-r-8 ${
                  step.number <= currentStep ? 'bg-black' : 'bg-gray-400'
                }`}
              />
              <button
                onClick={() => onStepChange(step.number)}
                className={`text-left ${
                  step.number === currentStep
                    ? 'text-primary'
                    : step.number < currentStep
                    ? 'text-primary/80'
                    : 'text-muted-foreground'
                }`}
              >
                <div className="text-sm font-medium">Passo {step.number}</div>
                <div className="text-xs mt-1">{step.title}</div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}