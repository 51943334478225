import React from 'react';

interface LabelProps {
    textLabel: string;
    htmlFor?: string;
}

const Label: React.FC<LabelProps> = ({ textLabel, htmlFor }) => {
    return (
        <div className="w-96 text-stone-950 text-sm font-medium font-['General Sans Variable'] leading-tight mt-5 mb-2">
            {textLabel}
        </div>
    );
};

export default Label;