import React from "react";

const LogoOrdaIcon: React.FC = () => (
    <svg
    width="24"
    height="24"
    viewBox="0 0 67 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <rect width="67" height="63"  />
    <path d="M-899 654H1661V-786H-899V654Z" />
    <mask
      id="mask0_0_1"
      maskUnits="userSpaceOnUse"
      x="-899"
      y="-786"
      width="2560"
      height="1440"
    >
      <path d="M-899 -786H1661V654H-899V-786Z"     fill="#C7FF04" />
    </mask>
    <g mask="url(#mask0_0_1)">
      <path
        d="M49.1835 56.3856L40.2635 49.9056C36.1435 46.911 30.6088 46.9123 26.4915 49.9056L17.5701 56.3856C17.4048 56.507 17.2408 56.5976 17.0808 56.6696L25.0701 62.4736L31.9781 57.4563C32.8141 56.8483 33.9395 56.8483 34.7768 57.4563L41.6835 62.4723L49.6701 56.6696C49.5115 56.5976 49.3488 56.507 49.1835 56.3856Z"
        fill="#000"
      />
      <path
        d="M5.79802 48.4729L13.786 54.2769C13.806 54.1022 13.842 53.9195 13.906 53.7235L17.3127 43.2369C18.886 38.3955 17.1767 33.1315 13.058 30.1395L4.13668 23.6582C3.98602 23.5489 3.85935 23.4342 3.74868 23.3169L0.700684 32.6982L7.57135 37.6902C8.40868 38.2982 8.75668 39.3675 8.43668 40.3515L5.79802 48.4729Z"
        fill="#000"
      />
      <path
        d="M66.0514 32.6993L63.0034 23.3179C62.8927 23.4353 62.7674 23.5486 62.6167 23.6579L53.6954 30.1393C49.5767 33.1313 47.8674 38.3953 49.4407 43.2353L52.8487 53.7246C52.9114 53.9179 52.9487 54.1006 52.9674 54.2739L60.9554 48.4699L58.3167 40.3513C57.9967 39.3673 58.3447 38.2979 59.1821 37.6899L66.0514 32.6993Z"
        fill="#000"
      />
      <path
        d="M35.6418 0.768372L39.0498 11.255C40.6218 16.0964 45.0992 19.351 50.1898 19.351H61.2178C61.4032 19.351 61.5712 19.3684 61.7285 19.3977L58.6805 10.0177H50.1898C49.1552 10.0177 48.2458 9.35504 47.9258 8.37104L45.3098 0.321705H35.4485C35.5205 0.457705 35.5872 0.601704 35.6418 0.768372Z"
        fill="#000"
      />
      <path
        d="M5.02197 19.3984C5.18064 19.3691 5.34997 19.3504 5.53664 19.3504H16.5633C21.654 19.3504 26.1313 16.0958 27.7046 11.2558L31.1113 0.767773C31.166 0.601107 31.2326 0.457106 31.3046 0.321106H21.4433L18.8273 8.37044C18.5086 9.35577 17.598 10.0171 16.5633 10.0171H8.06997L5.02197 19.3984Z"
        fill="#000"
      />
    </g>
  </svg>
);

export default LogoOrdaIcon;
