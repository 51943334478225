import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from '../views/page_home';
import Login from '../views/page_login';
import Finance from '../views/page_finance';
import FinanceClient from '../views/finance_client_page';
import TicketCreate from '../views/forms/form_tickets/form_ticket';
import TicketsReports from '../views/page_events_list';
import TicketsQRAcess from '../views/page_events_validate_access';
import MenuDrawerDefault from '../components/menu/menu_drawer_default';
import { authStore } from '../services/contexts/authStore';

const AppRouter = () => {
  const isLoggedIn = authStore((state) => state.isLoggedIn);
  const login = authStore((state) => state.login);

  return (
    <Router>
      {isLoggedIn ? (
        <div className='flex'>
          <MenuDrawerDefault />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/ticket-create" element={<TicketCreate />} />
              <Route path="/ticket-list" element={<TicketsReports />} />
              <Route path="/ticket-acesss" element={<TicketsQRAcess />} />
              <Route path="/finance" element={<Finance />} />
              <Route path="/finance-clients" element={<FinanceClient />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Login onLogin={login} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </Router>
  );
};

export default AppRouter;