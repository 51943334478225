import React, { useEffect, useState } from "react";
import HeaderPage from "../components/headers/header_pages";
import { Button } from "../components/buttons/button_finance";
import MenuDrawerDefault from "../components/menu/menu_drawer_default";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/selects/selects";
import { IProduct } from "../services/contexts/productStore";
import api from "../services/index";

const TicketsReports = () => {
  const [tickets, setTickets] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await api.get('/club/product/events');
        console.log(response.data);
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchTickets();
  }, []);

  return (
    <div className="h-screen w-screen bg-neutral-neutral100">
      <div className="flex h-full">
        <div className="flex-1">
          <HeaderPage title={"Eventos"} />
          <div className="p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-4">
              <h2 className="text-xl font-semibold">Todos os Eventos</h2>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
                <div className="relative w-full sm:w-auto"></div>
                <div className="flex items-center gap-2">
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">grid_view</span>
                  </Button>
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      format_list_bulleted
                    </span>
                  </Button>
                </div>
                <Select value="todas" onChange={(value) => console.log(value)}>
                  <SelectTrigger className="w-full sm:w-[100px]">
                    <SelectValue>Selecione</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="todas" onSelect={(value) => console.log(value)}>Todas</SelectItem>
                    <SelectItem value="pendentes" onSelect={(value) => console.log(value)}>Pendentes</SelectItem>
                    <SelectItem value="pagas" onSelect={(value) => console.log(value)}>Pagas</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>s

            {/* Table */}
            <div className="bg-white rounded-lg shadow-sm overflow-x-auto h-full">
              {loading ? (
                <div className="flex justify-center items-center h-full">
                  <span>Loading...</span>
                </div>
              ) : (
                <table className="w-full min-w-[640px]">
                  <thead className="bg-muted/50">
                    <tr>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Título
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Descrição
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Categoria
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Status
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Data
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Local
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map((ticket, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-4">{ticket.title}</td>
                        <td className="p-4">{ticket.description}</td>
                        <td className="p-4">{ticket.category}</td>
                        <td className="p-4">{ticket.events.status}</td>
                        <td className="p-4">{new Date(ticket.events.date).toLocaleDateString()}</td>
                        <td className="p-4">{ticket.events.location.name}</td>
                        <td className="p-4">
                          <Button variant="ghost" size="icon">
                            <span className="material-symbols-rounded">
                              more_vert
                            </span>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <div className="flex flex-col sm:flex-row items-center justify-between px-4 py-4 border-t gap-4">
                <p className="text-sm text-muted-foreground">
                  Mostrando 1 to 20 of 97 resultados
                </p>
                <div className="flex items-center gap-2">
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      chevron_left
                    </span>
                  </Button>
                  {[1, 2, 3, 4, 5, 6].map((page) => (
                    <Button
                      key={page}
                      variant={page === 1 ? "secondary" : "ghost"}
                      size="icon"
                      className="w-8 h-8"
                    >
                      {String(page).padStart(2, "0")}
                    </Button>
                  ))}
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      chevron_right
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketsReports;
