// src/Home.tsx
import React from 'react';
import BasePage from '../components/grids/page_login_base';
import FlowFeature from '../components/flows/exemple';
import ProductList from '../components/lists/list_products';
import HeaderPage from '../components/headers/header_pages';

const Products = () => {
  console.log('Home component rendered');
  return (
    <div className="h-screen w-screen bg-neutral-100">
      <div className="flex h-full">
        <div className="flex-1">
          <BasePage>
          <HeaderPage title={'Produtos'} />
            <ProductList products={[]}  />
          </BasePage>
        </div>
      </div>
    </div>
  );
};

export default Products;
