import React from 'react';
import StatCard from '../cards/cards_header';

const StatsGrid: React.FC = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-8">
      <StatCard title="A receber (vencidas)" value={20} />
      <StatCard title="A receber (vencem hoje)" value={40} />
      <StatCard title="A pagar (vencidas)" value={40} />
      <StatCard title="A pagar (vencem hoje)" value={10} />
      <StatCard title="Clientes inadimplentes" value={433} />
    </div>
  );
};

export default StatsGrid;