"use client";
import * as React from "react";
import { Check, ChevronDown } from "lucide-react";
import { cn } from "../../utils/utils_select";

const Select = ({ children, value, onChange }: { children: React.ReactNode; value: string; onChange: (value: string) => void }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const handleSelect = (val: string) => {
    onChange(val);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        className={cn(
          "flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        )}
        onClick={toggleOpen}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {value || "Select an option"}
        <ChevronDown className="h-4 w-4 opacity-50" />
      </button>

      {isOpen && (
        <ul
          className="absolute z-50 mt-2 w-full rounded-md border bg-popover text-popover-foreground shadow-md"
          role="listbox"
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child as React.ReactElement, { onSelect: handleSelect })
          )}
        </ul>
      )}
    </div>
  );
};

const SelectGroup = ({ children }: { children: React.ReactNode }) => <div>{children}</div>;

const SelectValue = ({ children }: { children: React.ReactNode }) => <span>{children}</span>;

const SelectTrigger = React.forwardRef<HTMLButtonElement, React.ComponentPropsWithoutRef<"button">>(
  ({ className, children, ...props }, ref) => (
    <button
      ref={ref}
      className={cn(
        "flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      {...props}
    >
      {children}
      <ChevronDown className="h-4 w-4 opacity-50" />
    </button>
  )
);

SelectTrigger.displayName = "SelectTrigger";

const SelectContent = ({ children }: { children: React.ReactNode }) => (
  <div className="p-1">{children}</div>
);

const SelectLabel = ({ children }: { children: React.ReactNode }) => (
  <div className="py-1.5 pl-8 pr-2 text-sm font-semibold">{children}</div>
);

const SelectItem = ({
  children,
  value,
  onSelect,
}: {
  children: React.ReactNode;
  value: string;
  onSelect: (value: string) => void;
}) => (
  <li
    className="relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground"
    role="option"
    onClick={() => onSelect(value)}
    tabIndex={0}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <Check className="h-4 w-4" />
    </span>
    {children}
  </li>
);

const SelectSeparator = () => <hr className="my-1 border-t border-muted" />;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
};