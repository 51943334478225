import React, { ReactNode } from 'react';
interface PageBaseProps {
  children: ReactNode;
}

const PageBase: React.FC<PageBaseProps> = ({ children }) => {
  return (
    <div className=" bg-neutral-neutral50 mx-auto flex max-w-[3840px] flex-col">
      <main className="flex grow flex-col">
        <main className="flex flex-col items-stretch">
          {children}
        </main>
      </main>
    </div>
  );
};

export default PageBase;