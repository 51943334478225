import React from 'react';

interface TitleH1Props {
    text: string;
}

function TitleH1({ text }: TitleH1Props) {
    return (
        <div className="text-text text-xl  leading-8 break-words" style={{ fontFamily: 'General Sans, sans-serif' }}>
            {text}
        </div>
    );
}

export default TitleH1;