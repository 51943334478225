"use client";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, ChevronUp } from "lucide-react";
import LogoOrdaIcon from "../../assets/icons/logo_orda_w_icon";
import { Button } from "../buttons/button_menu_drawer";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/grids/ui_colapse";

interface MenuItem {
  title: string;
  icon?: React.ReactNode;
  items?: { title: string; href: string }[];
  href?: string;
}

const menuItems: MenuItem[] = [
  {
    title: "Página inicial",
    icon: <span className="material-symbols-rounded"> home </span>,
    href: "/home",
  },
  {
    title: "Relatórios",
    icon: <span className="material-symbols-rounded"> bar_chart_4_bars </span>,
    href: "/reports",
  },
  {
    title: "Ingressos",
    icon: <span className="material-symbols-rounded"> confirmation_number </span>,
    items: [
      { title: "Criar Ingressos", href: "/create-tickets" },
      { title: "Mecanismo de Acesso", href: "/access" },
      { title: "Relatórios", href: "/reports/tickets" },
    ],
  },
  {
    title: "Financeiro",
    icon: <span className="material-symbols-rounded"> attach_money </span>,
    items: [
      { title: "Visão geral", href: "/finance" },
      { title: "Cobranças", href: "/charges" },
      { title: "Gerenciador", href: "/manager" },
    ],
  },
];

export default function MenuDrawerDefault() {
  const [openSections, setOpenSections] = useState<string[]>([]);

  const toggleSection = (title: string) => {
    setOpenSections((prev) =>
      prev.includes(title)
        ? prev.filter((section) => section !== title)
        : [...prev, title]
    );
  };

  return (
    <div className="flex h-screen w-64 flex-col border-r bg-neutral-neutral10">
      <div className="flex h-14 items-center border-b px-4">
        <div className="flex items-center gap-2">
          <div className="w-9 h-9 bg-brand-black rounded-lg flex justify-center items-center">
            <LogoOrdaIcon />
          </div>
          <div className="flex items-center justify-space-between space-y-1 items-center">
            <span className="flex text-xl font-semibold">ORDA</span>
            <div className="flex items-center space-y-1 m-3 rounded-full bg-black px-1">
              <span className="text-xs text-muted-foreground text-white p-1">
                beta
              </span>
            </div>
          </div>
        </div>
      </div>
      <nav className="flex-1 overflow-y-auto p-2">
        {menuItems.map((item) => (
          <div key={item.title} className="mb-1">
            {item.items ? (
              <Collapsible
                open={openSections.includes(item.title)}
                onOpenChange={() => toggleSection(item.title)}
              >
                <CollapsibleTrigger asChild>
                  <Button
                    variant="ghost"
                    className="flex w-full items-center justify-between px-3 py-2"
                  >
                    <div className="flex items-center gap-3">
                      {item.icon}
                      <span className="text-sm">{item.title}</span>
                    </div>
                    {openSections.includes(item.title) ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </Button>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <div className="ml-9 mt-1 space-y-1">
                    {item.items.map((subItem) => (
                      <Button
                        key={subItem.title}
                        variant="ghost"
                        className="w-full justify-start px-3 py-1 text-sm font-normal"
                        asChild
                      >
                        <Link to={subItem.href}>{subItem.title}</Link>
                      </Button>
                    ))}
                  </div>
                </CollapsibleContent>
              </Collapsible>
            ) : (
              <Button
                variant="ghost"
                className="flex w-full items-center justify-start gap-3 px-3 py-2"
                asChild
              >
                <Link to={item.href || "#"}>
                  {item.icon}
                  <span className="text-sm">{item.title}</span>
                </Link>
              </Button>
            )}
          </div>
        ))}
      </nav>
      <div className="flex h-14 items-center border-t px-4">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 bg-neutral-neutral900 rounded-full flex justify-center items-center"></div>
          <div className="flex flex-col items-start">
            <span className="text-sm font-semibold">Felipe Consalter</span>
            <span className="text-xs text-muted-foreground">beta</span>
          </div>
        </div>
      </div>
    </div>
  );
}
