import React from "react";
import HeaderPage from "../components/headers/header_pages";
import MenuDrawerDefault from "../components/menu/menu_drawer_default";
import { Button } from "../components/buttons/button_finance";
import QrCodeScanner from "../components/cards/card_qrcode_reader";

const ticketData = [
  {
    title: "Evento 1",
    description: "Descrição do Evento 1",
    type: "ticket",
    category: "Categoria 1",
    ticket: {
      status: "Disponível",
      teamOne: "Time A",
      teamTwo: "Time B",
      teamOneLogo: "logoA.png",
      teamTwoLogo: "logoB.png",
      teamOneScore: 0,
      teamTwoScore: 0,
      location: {
        name: "Estádio 1",
        address: "Rua 1",
        city: "Cidade 1",
        state: "Estado 1",
        zip: "00000-000",
      },
      date: "2023-10-01",
      endAvaliable: "2023-10-01",
      time: "18:00",
      seat: "A1",
      lotes: "Lote 1",
      rules: "Regras do Evento 1",
    },
    store: {
      size: "M",
      color: "Azul",
    },
    price: 100,
    splitPercentage: 10,
    avaliable: 50,
  },
  {
    title: "Evento 1",
    description: "Descrição do Evento 1",
    type: "ticket",
    category: "Categoria 1",
    ticket: {
      status: "Disponível",
      teamOne: "Time A",
      teamTwo: "Time B",
      teamOneLogo: "logoA.png",
      teamTwoLogo: "logoB.png",
      teamOneScore: 0,
      teamTwoScore: 0,
      location: {
        name: "Estádio 1",
        address: "Rua 1",
        city: "Cidade 1",
        state: "Estado 1",
        zip: "00000-000",
      },
      date: "2023-10-01",
      endAvaliable: "2023-10-01",
      time: "18:00",
      seat: "A1",
      lotes: "Lote 1",
      rules: "Regras do Evento 1",
    },
    store: {
      size: "M",
      color: "Azul",
    },
    price: 100,
    splitPercentage: 10,
    avaliable: 50,
  },
  // Adicione mais objetos conforme necessário
];

const TicketsQRAcess = () => {
  const [data, setData] = React.useState<string>("");
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const openModalQR = () => {
    setIsModalOpen(true);
  };

  const closeModalQR = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="h-screen w-screen bg-neutral-neutral100">
      <div className="flex h-full">
        <div className="flex-1">
          <HeaderPage title={"Leitor de Acessos"} />
          <div className="p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-4">
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
                <div className="relative w-full sm:w-auto"></div>
                <div className="flex items-center gap-2"></div>
              </div>
              <button
                type="button"
                className="bg-brand-black text-white px-4 py-2 rounded-[32px]"
                onClick={openModalQR}
              >
                Validar Acesso
              </button>
            </div>

            <div className="bg-white rounded-lg shadow-sm flex-1 h-full">
              <div>
                <table className="w-full min-w-[640px]">
                  <thead className="bg-muted/50">
                    <tr>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Título
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Descrição
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Categoria
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Status
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Data
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Local
                      </th>
                      <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketData.map((ticket, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-4">{ticket.title}</td>
                        <td className="p-4">{ticket.description}</td>
                        <td className="p-4">{ticket.category}</td>
                        <td className="p-4">{ticket.ticket.status}</td>
                        <td className="p-4">{ticket.ticket.date}</td>
                        <td className="p-4">{ticket.ticket.location.name}</td>
                        <td className="p-4">
                          <Button variant="ghost" size="icon">
                            <span className="material-symbols-rounded">
                              more_vert
                            </span>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="flex flex-col sm:flex-row items-center justify-between px-4 py-4 border-t gap-4">
                  <p className="text-sm text-muted-foreground">
                    Mostrando 1 to 20 of 97 resultados
                  </p>
                  <div className="flex items-center gap-2">
                    <Button variant="outline" size="icon">
                      <span className="material-symbols-rounded">
                        chevron_left
                      </span>
                    </Button>
                    {[1, 2, 3, 4, 5, 6].map((page) => (
                      <Button
                        key={page}
                        variant={page === 1 ? "secondary" : "ghost"}
                        size="icon"
                        className="w-8 h-8"
                      >
                        {String(page).padStart(2, "0")}
                      </Button>
                    ))}
                    <Button variant="outline" size="icon">
                      <span className="material-symbols-rounded">
                        chevron_right
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Modal de Validação</h2>
            <p>Conteúdo do modal...</p>
            <button
              type="button"
              className="mt-4 bg-brand-black text-white px-4 py-2 rounded-[32px]"
              onClick={closeModalQR}
            >
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketsQRAcess;
