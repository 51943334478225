import React from "react";
import { Bell, HelpCircle, Mail } from "lucide-react";

interface HeaderProps {
  title: string;
}

export default function Header({ title }: HeaderProps) {
  return (
    <header className="flex h-16 items-center justify-between border-b px-4 shadow-sm">
      <div className="flex items-center gap-3">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary/10">
          <span className="text-lg font-semibold text-primary">$</span>
        </div>
        <h1 className="text-lg font-medium">{title}</h1>
      </div>
      <div className="flex items-center gap-4">
        <button className="rounded-full p-2 hover:bg-gray-100">
          <Mail className="h-5 w-5 text-gray-600" />
          <span className="sr-only">Messages</span>
        </button>
        <button className="rounded-full p-2 hover:bg-gray-100">
          <HelpCircle className="h-5 w-5 text-gray-600" />
          <span className="sr-only">Help</span>
        </button>
        <button className="rounded-full p-2 hover:bg-gray-100">
          <Bell className="h-5 w-5 text-gray-600" />
          <span className="sr-only">Notifications</span>
        </button>
      </div>
    </header>
  );
}