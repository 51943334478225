// src/Home.tsx
import React from "react";
import HeaderPage from "../components/headers/header_pages";
import StatsGrid from "../components/lists/list_stats_grid";
import { Button } from "../components/buttons/button_finance";
import MenuDrawerDefault from "../components/menu/menu_drawer_default";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "../components/selects/selects";

const data = [
  {
    _id: "6736da0c42e6fccbd0b6b479",
    id_orda: "123456",
    id_asaas: "cus_000101985995",
    name: "Felipe Consalter",
    cpfCnpj: "023.644.100-02",
    email: "john.doe@example.com",
    phone: "123456789",
    mobilePhone: "987654321",
    address: "123 Main St",
    addressNumber: "456",
    complement: "Apt 789",
    province: "SP",
    postalCode: "12345-678",
    externalReference: "ext_ref_001",
    notificationDisabled: false,
    additionalEmails: "jane.doe@example.com",
    municipalInscription: "123456",
    stateInscription: "654321",
    observations: "No observations",
    groupName: "Group A",
    company: "Company XYZ",
    foreignCustomer: false,
    __v: 0,
  },
];

const FinanceClient = () => {
  console.log("Home component rendered");
  return (
    <div className="h-screen w-screen bg-neutral-neutral100">
      <div className="flex h-full">
        <div className="flex-1">
          <HeaderPage title={"Financeiro"} />
          <div className="p-4 sm:p-6">
            {/* Header */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
              <h1 className="text-xl font-semibold mb-2 sm:mb-0">
                Todas as cobranças
              </h1>
              <Button variant="secondary" size="sm">
                Ver tudo
              </Button>
            </div>

            <StatsGrid />

            {/* Table Header */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-4">
              <h2 className="text-xl font-semibold">Clientes Sincronizados com o Fiannceiro</h2>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
                <div className="relative w-full sm:w-auto">
                  {/* <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground w-4 h-4" /> */}
                  {/* <Input
                      placeholder="Busque cobrança"
                      className="pl-9 w-full sm:w-[300px]"
                    /> */}
                </div>
                <div className="flex items-center gap-2">
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">grid_view</span>
                  </Button>
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      format_list_bulleted
                    </span>
                  </Button>
                </div>
                <Select value="todas" onChange={() => {}}>
                  <SelectTrigger className="w-full sm:w-[100px]">
                    <SelectValue>todas</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="todas" onSelect={() => {}}>Todas</SelectItem>
                    <SelectItem value="pendentes" onSelect={() => {}}>Pendentes</SelectItem>
                    <SelectItem value="pagas" onSelect={() => {}}>Pagas</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Table */}
            <div className="bg-white rounded-lg shadow-sm overflow-x-auto h-full">
              <table className="w-full min-w-[640px]">
                <thead className="bg-muted/50">
                  <tr>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Nome cliente
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      CPF/CNPJ
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Email
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Telefone
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Endereço
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Empresa
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => (
                    <tr key={row._id} className="border-b">
                      <td className="p-4">{row.name}</td>
                      <td className="p-4">{row.cpfCnpj}</td>
                      <td className="p-4">{row.email}</td>
                      <td className="p-4">{row.phone}</td>
                      <td className="p-4">
                        {row.address}, {row.addressNumber}, {row.complement},{" "}
                        {row.province}, {row.postalCode}
                      </td>
                      <td className="p-4">{row.company}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex flex-col sm:flex-row items-center justify-between px-4 py-4 border-t gap-4">
                <p className="text-sm text-muted-foreground">
                  Mostrando 1 to 1 of 1 resultado
                </p>
                <div className="flex items-center gap-2">
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      chevron_left
                    </span>
                  </Button>
                  <Button variant="secondary" size="icon" className="w-8 h-8">
                    01
                  </Button>
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      chevron_right
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceClient;