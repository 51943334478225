import React from 'react';
import { useNavigate } from 'react-router-dom';
import EmailTextInput from '../components/inputs/text_cpf_input';
import PasswordTextInput from '../components/inputs/text_password_input';
import PageBase from '../components/grids/page_login_base';
import Divider from '../components/grids/divider';
import TitleH2Text from '../components/texts/title_h2_text';
import TitleH1Text from '../components/texts/title_h1_text';
import { Button } from '../components/buttons';
import api from '../services';

import imageLogin from '../assets/img-login.png';
import IconGoogle from '../assets/icons/google_icon';
import { authStore, IAuthState } from '../services/contexts/authStore';

interface LoginProps {
    onLogin?: () => void;
}
const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const navigate = useNavigate();
    const [cpf, setCpf] = React.useState('');
    const { updateUser, updateToken } = authStore() as IAuthState;

    const [password, setPassword] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);

    const handleLogin = async () => {
        setLoading(true);
        try {
            if (!cpf || !password) {
                setAlertMessage('Por favor, preencha todos os campos.');
                setLoading(false);
                return;
            }
            const response = await api.post('/auth/auth/local', {
                cpf: cpf,
                password,
            });
            if (response.status === 200) {
                const { token, user } = response.data;
                updateUser(user);
                updateToken(token);
                if (onLogin) onLogin();
                navigate('/home');
            } else {
                setAlertMessage('Usuário ou senha inválidos');
                console.error('Login failed:', response.data.message);
            }
        } catch (error) {
            setAlertMessage('Usuário ou senha inválidos');
            console.error('An error occurred during login:', error);
        } finally {
            setLoading(false);
        }
    };

    const forgotPassword = () => {
        navigate('/forgot-password');
    };

    const siginWithGoogle = () => {
        navigate('/');
    };

    return (
        <PageBase>
            <div className="flex flex-col md:flex-row min-720:justify-center h-screen">
                <div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-10 px-10 md:gap-40 md:px-40">
                    <div className="flex flex-col">
                        <TitleH1Text text="Acesse sua conta" />
                        <Divider height="40px" />
                        <TitleH2Text
                            text="Seja bem-vindo(a)! Por favor, insira suas credenciais para a nossa plataforma ou Crie uma conta"
                            textColor="DEFAULT"
                        />
                        {alertMessage && (
                            <div
                                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                role="alert"
                            >
                                <span className="block sm:inline">
                                    {alertMessage}
                                </span>
                                <span
                                    className="absolute top-0 bottom-0 right-0 px-4 py-3"
                                    onClick={() => setAlertMessage(null)}
                                >
                                    <svg
                                        className="fill-current h-6 w-6 text-red-500"
                                        role="button"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <title>Close</title>
                                        <path d="M14.348 5.652a1 1 0 00-1.414 0L10 8.586 7.066 5.652a1 1 0 10-1.414 1.414L8.586 10l-2.934 2.934a1 1 0 101.414 1.414L10 11.414l2.934 2.934a1 1 0 001.414-1.414L11.414 10l2.934-2.934a1 1 0 000-1.414z" />
                                    </svg>
                                </span>
                            </div>
                        )}
                        <form onSubmit={(event) => event.preventDefault()}>
                            <EmailTextInput
                                textLabel="CPF: "
                                placeholder="000.000.000-00"
                                onChange={setCpf}
                            />
                            <PasswordTextInput
                                textLabel="senha:  "
                                placeholder="****"
                                value={password}
                                onChange={setPassword}
                            />
                            <Divider height="20px" />
                            <span
                                className="w-96 text-brand-black text-sm font-medium font-['General Sans Variable'] leading-tight underline"
                                onClick={forgotPassword}
                            >
                                Esqueci minha senha?
                            </span>
                            <Button
                                label={loading ? 'Carregando...' : 'Entrar'}
                                color="bg-brand-black"
                                textColor="text-text-light"
                                action={handleLogin}
                                disabled={loading}
                            />
                        </form>
                        {/* <div className="w-full h-full flex items-center gap-8">
              <div className="flex-1 border border-text"></div>
              <div className=" flex flex-col justify-center items-center gap-4">
                <div className="text-text text-sm font-medium dark:text-text">
                  ou
                </div>
              </div>
              <div className="flex-1 border border-text"></div>
            </div>  */}
                        {/* <Button
              startIcon={<IconGoogle />}
              label="Continuar com o Google"
              color="bg-neutral-neutral10"
              textColor="text-text-dark"
              action={siginWithGoogle}
            /> */}
                    </div>
                </div>
                <div className="hidden md:block md:w-1/2">
                    <img
                        src={imageLogin}
                        alt="Imagem aleatória"
                        className="object-cover w-full h-full object-left"
                    />
                </div>
            </div>
        </PageBase>
    );
};

export default Login;
