import React, { useState } from "react";
import HeaderPage from "../../../components/headers/header_pages";
import { Button } from "../../../components/buttons/button_finance";
import MenuDrawerDefault from "../../../components/menu/menu_drawer_default";
import Stepper from "../../../components/headers/header_steps_form";
import Label from "../../../components/label/label_input";
import api from "../../../services";
interface Lote {
  loteName: string;
  price: number;
  avaliable: number;
  startSale: string;
  endSale: string;
}

const TicketCreate: React.FC = () => {
  const [lotes, setLotes] = useState([
    { loteName: "Lote 01", price: 0, avaliable: 0, startSale: "", endSale: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    type: "ticket",
    category: "",
    ticket: {
      status: "",
      teamOne: "",
      teamTwo: "",
      teamOneLogo: "",
      teamTwoLogo: "",
      teamOneScore: 0,
      teamTwoScore: 0,
      location: {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
      },
      date: "",
      endAvaliable: "",
      time: "",
      seat: "",
      lotes: lotes,
      rules: "",
    },
    store: {
      size: "",
      color: "",
    },
    price: 0,
    splitPercentage: 0,
    avaliable: 0,
  });

  const handleInputChange = (
    index: number,
    field: keyof Lote,
    value: string | number
  ) => {
    const updatedLotes = [...lotes];
    (updatedLotes[index][field] as string | number) = value;
    setLotes(updatedLotes);
  };

  const handleAddLote = () => {
    setLotes([
      ...lotes,
      { loteName: "", price: 0, avaliable: 0, startSale: "", endSale: "" },
    ]);
  };

  const handleRemoveLote = (index: number) => {
    if (lotes.length > 1) {
      const updatedLotes = lotes.filter((_, i) => i !== index);
      setLotes(updatedLotes);
    }
  };

  const [currentStep, setCurrentStep] = useState(1);
  const steps = [
    { number: 1, title: "Informações Basicas" },
    { number: 2, title: "Detalhes do Ingresso" },
    { number: 3, title: "Lotes" },
    { number: 4, title: "Endereço" },
    { number: 5, title: "Resumo" },
  ];


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    if (keys.length > 1) {
      setFormData((prevState) => {
        let updatedData = { ...prevState };
        let nestedData = updatedData;
        for (let i = 0; i < keys.length - 1; i++) {
          nestedData = (nestedData as any)[keys[i]];
        }
        (nestedData as any)[keys[keys.length - 1]] = value;
        return updatedData;
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await api.post("/club/product", formData);
      if (response.status === 200) {
        window.location.href = "/ticket-list";
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

    }

  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="h-screen w-screen bg-neutral-neutral100">
      <div className="flex h-full">
        <div className="flex-1">
          <HeaderPage title={"Criar Ingresso"} />
          <div className="p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
              <h1 className="text-xl font-semibold mb-2 sm:mb-0">
                Criar Ingresso
              </h1>
            </div>
            <Stepper
              steps={steps}
              currentStep={currentStep}
              onStepChange={setCurrentStep}
            />
            <div className="bg-white rounded-[10px] shadow-sm overflow-x-auto h-full w-full p-10">
              <form onSubmit={handleSubmit}>
                {currentStep === 1 && (
                  <div className="">
                    <div></div>
                    <div className="mb-4">
                      <Label title="Titulo" />
                      <input
                        className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <Label title="Descrição" />
                      <textarea
                        className=" w-full h-20 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <Label title="Categoria" />
                      <input
                        className="w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                        type="text"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="bg-brand-black text-white px-4 py-2 rounded-[32px]"
                        onClick={nextStep}
                      >
                        Avançar
                      </button>
                    </div>
                  </div>
                )}
                {currentStep === 2 && (
                  <div>
                    {formData.type === "ticket" && (
                      <div>
                        <div className="mb-4">
                          <Label title="Nome Time da Casa" />
                          <input
                            className="w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                            type="text"
                            name="ticket.teamOne"
                            value={formData.ticket.teamOne}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4">
                          <Label title="Nome Time Dois" />
                          <input
                            className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                            type="text"
                            name="ticket.teamTwo"
                            value={formData.ticket.teamTwo}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4">
                          <Label title="Logo Do time Dois" />
                          <input
                            className="w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                            type="text"
                            name="ticket.teamOneLogo"
                            value={formData.ticket.teamOneLogo}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4">
                          <Label title="Logo Time Dois" />
                          <input
                            className="w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                            type="text"
                            name="ticket.teamTwoLogo"
                            value={formData.ticket.teamTwoLogo}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4">
                          <Label title="Horario" />
                          <input
                            className="w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                            type="time"
                            name="ticket.time"
                            value={formData.ticket.time}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4">
                          <Label title="Regras" />
                          <textarea
                            className="w-full h-20 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                            name="ticket.rules"
                            value={formData.ticket.rules}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}

                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="bg-neutral-neutral100 text-text-dark px-4 py-2 rounded-[32px] mr-5"
                        onClick={prevStep}
                      >
                        Voltar
                      </button>
                      <button
                        type="button"
                        className="bg-brand-black text-white px-4 py-2 rounded-[32px]"
                        onClick={nextStep}
                      >
                        Avançar
                      </button>
                    </div>
                  </div>
                )}
                {currentStep === 3 && (
                  <div>
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                            Nome do Lote
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Preço
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Disponível
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Início das Vendas
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Fim das Vendas
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {lotes.map((lote, index) => (
                          <tr key={index}>
                            <td className="">
                              <input
                                className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                                type="text"
                                placeholder="Nome do lote"
                                value={lote.loteName}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "loteName",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                                type="number"
                                placeholder="Preço"
                                value={lote.price}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td >
                              <input
                                className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                                type="number"
                                placeholder="Disponível"
                                value={lote.avaliable}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "avaliable",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td className="whitespace-nowrap">
                              <input
                                className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                                type="date"
                                value={lote.startSale}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "startSale",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td >
                              <input
                                className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                                type="date"
                                value={lote.endSale}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "endSale",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td className="flex py-3 whitespace-nowrap items-center justify-center align-center">
                              <button
                                className="text-text-default hover:underline"
                                onClick={() => handleRemoveLote(index)}
                              >
                                <span className="material-symbols-rounded">
                                  delete
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button
                      className="mt-4 px-4 py-2 bg-brand-black text-white rounded-[30px]"
                      onClick={handleAddLote}
                    >
                      Adicionar Lote
                    </button>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="bg-neutral-neutral100 text-text-dark px-4 py-2 rounded-[32px] mr-5"
                        onClick={prevStep}
                      >
                        Voltar
                      </button>
                      <button
                        type="button"
                        className="bg-brand-black text-white px-4 py-2 rounded-[32px]"
                        onClick={nextStep}
                      >
                        Avançar
                      </button>
                    </div>
                  </div>
                )}
                {currentStep === 4 && (
                  <div>
                    <div className="mb-4">
                      <Label
                        title="
                        Nome Para a Localização"
                      />
                      <input
                        className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                        type="text"
                        name="ticket.location.name"
                        value={formData.ticket.location.name}
                        onChange={handleChange}
                      />
                    <div className="mb-4">
                      <Label title="CEP" />
                      <input
                        className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                        type="text"
                        name="ticket.location.zip"
                        value={formData.ticket.location.zip}
                        onChange={handleChange}
                      />
                    </div>
                    </div>
                    <div className="mb-4">
                      <Label title="Endereço" />
                      <input
                        className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                        type="text"
                        name="ticket.location.address"
                        value={formData.ticket.location.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <Label title="Cidade" />
                      <input
                        className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                        type="text"
                        name="ticket.location.city"
                        value={formData.ticket.location.city}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <Label title="Estado" />
                      <input
                        className=" w-full h-9 pl-3 pr-2 py-2 bg-neutral-neutral100 rounded-lg justify-start items-center gap-1 inline-flex border border-neutral-neutral100"
                        type="text"
                        name="ticket.location.state"
                        value={formData.ticket.location.state}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="bg-neutral-neutral100 text-text-dark px-4 py-2 rounded-[32px] mr-5"
                        onClick={prevStep}
                      >
                        Voltar
                      </button>
                      <button
                        type="button"
                        className="bg-brand-black text-white px-4 py-2 rounded-[32px]"
                        onClick={nextStep}
                      >
                        Avançar
                      </button>
                    </div>
                  </div>
                )}
                {currentStep === 5 && (
                  <div>
                    <div className="mb-4">
                        <Label title="Logo Time Dois" />
                        <p>{formData.ticket.teamTwoLogo}</p>
                      </div>
                      <div className="mb-4">
                        <Label title="Horario" />
                        <p>{formData.ticket.time}</p>
                      </div>
                      <div className="mb-4">
                        <Label title="Regras" />
                        <p>{formData.ticket.rules}</p>
                      </div>
                      <div className="mb-4">
                        <Label title="Localização" />
                        <p>{formData.ticket.location.name}</p>
                      </div>
                      <div className="mb-4">
                        <Label title="Endereço" />
                        <p>{formData.ticket.location.address}</p>
                      </div>
                      <div className="mb-4">
                        <Label title="Cidade" />
                        <p>{formData.ticket.location.city}</p>
                      </div>
                      <div className="mb-4">
                        <Label title="Estado" />
                        <p>{formData.ticket.location.state}</p>
                      </div>
                      <div className="mb-4">
                        <Label title="CEP" />
                        <p>{formData.ticket.location.zip}</p>
                      </div>
                      <div className="mb-4">
                        <Label title="Lotes" />
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nome do Lote</th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Preço</th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Disponível</th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Início das Vendas</th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Fim das Vendas</th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {formData.ticket.lotes.map((lote, index) => (
                              <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap">{lote.loteName}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{lote.price}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{lote.avaliable}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{lote.startSale}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{lote.endSale}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="bg-neutral-neutral100 text-text-dark px-4 py-2 rounded-[32px] mr-5"
                        onClick={prevStep}
                      >
                        Voltar
                      </button>
                      <button
                        type="submit"
                        className="bg-brand-black text-white px-4 py-2 rounded-[32px]"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCreate;
