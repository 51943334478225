import React from "react";

interface LabelInputProps {
  title: string;
}

const LabelInput: React.FC<LabelInputProps> = ({ title }) => {
  return (
    <div className="mb-2">
      <label className="text-[#0c0c0c] text-sm font-medium font-['General Sans Variable'] leading-tight">
        {title}
      </label>
    </div>
  );
};

export default LabelInput;
