import React, { ChangeEvent, useState } from "react";
import Label from "../texts/label_text";

interface PasswordTextInputProps {
  textLabel: string;
  onChange: (value: string) => void;
  placeholder: string;
  value?: string;
}

const PassWordTextInput: React.FC<PasswordTextInputProps> = ({
  textLabel,
  onChange,
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

return (
    <div className="sm:col-span-4">
        <Label textLabel={textLabel} />
        <div className="mt-2">
            <div className="">
                <div className="relative">
                    <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="password"
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        className="text-neutral-500 text-sm font-medium font-['General Sans Variable'] leading-tight w-full h-11 pl-3 pr-2 py-3 bg-neutral-200 rounded-lg border border-stone-300 justify-start items-center gap-1 inline-flex"
                    />
                    <button className="absolute right-2 top-1/2 transform -translate-y-1/2" onClick={togglePasswordVisibility}>
                        <span className="material-symbols-rounded text-text" style={{ verticalAlign: "middle" }}>{showPassword ? "visibility_off" : "visibility"}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
);
};

export default PassWordTextInput;
