'use client';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';
import LogoOrdaIcon from '../../assets/icons/logo_orda_w_icon';
import { Button } from '../buttons/button_menu_drawer';
import { authStore, IAuthState } from '../../services/contexts/authStore';

interface MenuItem {
    title: string;
    icon?: React.ReactNode;
    items?: { title: string; href: string }[];
    href?: string;
}

const menuItems: MenuItem[] = [
    {
        title: 'Página inicial',
        icon: <span className="material-symbols-rounded">home</span>,
        href: '/home',
    },
    {
        title: 'Relatórios',
        icon: <span className="material-symbols-rounded">bar_chart_4_bars</span>,
        href: '/reports',
    },
    {
        title: 'Ingressos',
        icon: <span className="material-symbols-rounded">confirmation_number</span>,
        items: [
            { title: 'Criar Ingressos', href: '/ticket-create' },
            { title: 'Lista de Ingressos', href: '/ticket-list' },
            { title: 'Acesso a Evento', href: '/ticket-acesss' },
        ],
    },
    {
        title: 'Financeiro',
        icon: <span className="material-symbols-rounded">attach_money</span>,
        items: [
            { title: 'Visão geral', href: '/finance' },
            { title: 'Cobranças', href: '/finance-charge' },
            { title: 'Gerenciador', href: '/finance-clients' },
        ],
    },
    // Adicione outros itens do menu conforme necessário
];

export default function MenuDrawerDefault() {
    const [openSections, setOpenSections] = useState<string[]>([]);
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    const { authUser, clearState } = authStore() as IAuthState;

    const toggleSection = (title: string) => {
        setOpenSections((prev) =>
            prev.includes(title) ? prev.filter((section) => section !== title) : [...prev, title],
        );
    };

    const handleLogout = () => {
        clearState();
        window.location.href = '/';
    };

    const handleConfirmLogout = () => {
        console.log('handleConfirmLogout');
        setShowLogoutDialog(true);
    };

    return (
        <>
            {showLogoutDialog && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none bg-black bg-opacity-50">
                    <div className="relative w-auto mx-auto max-w-sm">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-200 rounded-t">
                                <h3 className="text-xl font-semibold">Deseja sair?</h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowLogoutDialog(false)}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            <div className="relative p-6 flex-auto">
                                <p className="my-4 text-gray-600 text-lg leading-relaxed">
                                    Tem certeza de que deseja sair?
                                </p>
                            </div>
                            <div className="flex items-center justify-end rounded-b">
                                <Button variant="ghost" onClick={() => setShowLogoutDialog(false)}>
                                    Cancelar
                                </Button>
                                <Button variant="ghost" onClick={handleLogout}>
                                    Sair
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex h-screen w-64 flex-col border-r bg-neutral-neutral10">
            <div className="flex h-14 items-center border-b px-4">
                    <div className="flex items-center gap-2">
                        <div className="w-9 h-9 bg-brand-electricLime rounded-lg flex justify-center items-center">
                            <LogoOrdaIcon />
                        </div>
                        <div className="flex items-center justify-space-between space-y-1 items-center">
                            <div className="flex flex-col">
                                <div className="flex items-center space-x-2">
                                    <span className="text-xl font-semibold">
                                        Orda
                                    </span>
                                    <div className="flex items-center m-1 rounded-full bg-black px-1">
                                        <span className="text-xs text-brand-electricLime p-1 font-medium text-left">
                                            Beta
                                        </span>
                                    </div>
                                </div>

                                <span className="flex text-xs text-muted-foreground mb-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="flex-1 overflow-y-auto p-2">
                    {menuItems.map((item) => (
                        <div key={item.title} className="mb-1">
                            {item.items ? (
                                <>
                                    <button
                                        className="flex w-full items-center justify-between px-3 py-2"
                                        onClick={() => toggleSection(item.title)}
                                    >
                                        <div className="flex items-center gap-3">
                                            {item.icon}
                                            <span className="text-sm">{item.title}</span>
                                        </div>
                                        {openSections.includes(item.title) ? (
                                            <ChevronUp className="h-4 w-4" />
                                        ) : (
                                            <ChevronDown className="h-4 w-4" />
                                        )}
                                    </button>
                                    {openSections.includes(item.title) && (
                                        <div className="ml-9 mt-1 space-y-1">
                                            {item.items.map((subItem) => (
                                                <Button
                                                    key={subItem.title}
                                                    variant="ghost"
                                                    className="w-full justify-start px-3 py-1 text-sm font-normal"
                                                    asChild
                                                >
                                                    <Link to={subItem.href}>{subItem.title}</Link>
                                                </Button>
                                            ))}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Button
                                    variant="ghost"
                                    className="flex w-full items-center justify-start gap-3 px-3 py-2"
                                    asChild
                                >
                                    <Link to={item.href || '#'}>
                                        {item.icon}
                                        <span className="text-sm">{item.title}</span>
                                    </Link>
                                </Button>
                            )}
                        </div>
                    ))}
                </nav>
                <div className="flex h-14 items-center border-t px-4">
                    <div className="flex w-full items-center justify-between">
                        {/* <div className="w-8 h-8 bg-neutral-neutral900 rounded-full flex justify-center items-center"></div> */}
                        <div className="flex w-full flex-col items-start">
                            <span className="text-sm font-semibold">
                                {authUser.name}
                            </span>
                            <span className="text-xs text-muted-foreground">
                                {authUser.email}
                            </span>
                        </div>
                        <Button
                            variant="ghost"
                            className="flex items-center gap-2"
                            onClick={handleConfirmLogout}
                        >
                            <span className="material-symbols-rounded">
                                logout
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}