import { create } from 'zustand';

export interface IAuthUser {
    id: string;
    name: string;
    email: string;
    phone: string;
    cpf: string;
    address: string;
}

export interface IAuthState {
    authUser: IAuthUser;
    token: string;
    isLoggedIn: boolean;
    setState: () => void;
    clearState: () => void;
    updateState: (state: IAuthState) => void;
    updateUser: (authUser: IAuthUser) => void;
    updateToken: (token: string) => void;
    login: () => void;
}

const initialAuthUser: IAuthUser = {
    id: '',
    name: '',
    email: '',
    phone: '',
    cpf: '',
    address: '',
};

export const authStore = create<IAuthState>((set) => ({
    authUser: initialAuthUser,
    token: '',
    isLoggedIn: false,
    setState: () =>
        set((state: IAuthState) => ({
            authUser: state.authUser,
            token: state.token,
        })),
    clearState: () =>
        set({ authUser: initialAuthUser, token: '', isLoggedIn: false }),
    updateState: (state: IAuthState) => set(state),
    updateUser: (authUser: IAuthUser) => set({ authUser: authUser }),
    updateToken: (token: string) => set({ token: token }),
    login: () => set({ isLoggedIn: true }),
}));
