import React from 'react';

interface TitleH2TextProps {
    text: string;
    textColor: string;
}

const TitleH2Text: React.FC<TitleH2TextProps> = ({ text, textColor }) => {
    return (
        <div className="w-full">
            <span className={`text-${textColor} text-base font-sans font-normal leading-6 break-words`} style={{ fontFamily: 'General Sans, sans-serif' }}>
                {text}
            </span>
        </div>
    );
};

export default TitleH2Text;