// src/components/VerticalSpacer.js
import React from 'react';
import PropTypes from 'prop-types';

const VerticalSpacer = ({ height }: { height: string }) => {
  return (
    <div style={{ height }}></div>
  );
};

VerticalSpacer.propTypes = {
  height: PropTypes.string.isRequired,
};

export default VerticalSpacer;
