// src/Home.tsx
import React from "react";
import HeaderPage from "../components/headers/header_pages";
import StatsGrid from "../components/lists/list_stats_grid";
import { Button } from "../components/buttons/button_finance";
import MenuDrawerDefault from "../components/menu/menu_drawer_default";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "../components/selects/selects";

const Finance = () => {
  console.log("Home component rendered");
  return (
    <div className="h-screen w-screen bg-neutral-neutral100">
      <div className="flex h-full">
        <MenuDrawerDefault />
        <div className="flex-1">
          <HeaderPage title={"Financeiro"} />
          <div className="p-4 sm:p-6">
            {/* Header */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
              <h1 className="text-xl font-semibold mb-2 sm:mb-0">
                Todas as cobranças
              </h1>
              <Button variant="secondary" size="sm">
                Ver tudo
              </Button>
            </div>

            <StatsGrid />

            {/* Table Header */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-4">
              <h2 className="text-xl font-semibold">Todas as cobranças</h2>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
                <div className="relative w-full sm:w-auto">
                  {/* <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground w-4 h-4" /> */}
                  {/* <Input
                      placeholder="Busque cobrança"
                      className="pl-9 w-full sm:w-[300px]"
                    /> */}
                </div>
                <div className="flex items-center gap-2">
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">grid_view</span>
                  </Button>
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      format_list_bulleted
                    </span>
                  </Button>
                </div>
                <Select defaultValue="todas">
                  <SelectTrigger className="w-full sm:w-[100px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="todas">Todas</SelectItem>
                    <SelectItem value="pendentes">Pendentes</SelectItem>
                    <SelectItem value="pagas">Pagas</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Table */}
            <div className="bg-white rounded-lg shadow-sm overflow-x-auto h-full">
              <table className="w-full min-w-[640px]">
                <thead className="bg-muted/50">
                  <tr>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Nome cliente
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Valor
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Descrição
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Setor
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Forma de pagamento
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Vencimento
                    </th>
                    <th className="text-left p-4 text-sm font-medium text-muted-foreground">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      name: "Fabricio Raimondi",
                      value: "R$ 351,02",
                      description: "Pagamento de mídias",
                      sector: "Marketing",
                      payment: "Boleto bancário",
                      dueDate: "7/11/19",
                    },
                    {
                      name: "Brooklyn Simmons",
                      value: "R$ 351,02",
                      description: "Mensalidade",
                      sector: "Financeiro",
                      payment: "PIX",
                      dueDate: "8/30/14",
                    },
                    {
                      name: "Bessie Cooper",
                      value: "R$ 351,02",
                      description: "Mensalidade",
                      sector: "Financeiro",
                      payment: "Boleto bancário",
                      dueDate: "9/4/12",
                    },
                    {
                      name: "Darlene Robertson",
                      value: "R$ 351,02",
                      description: "Pagamento de mídias",
                      sector: "Marketing",
                      payment: "PIX",
                      dueDate: "5/7/16",
                    },
                    {
                      name: "Jerome Bell",
                      value: "R$ 351,02",
                      description: "Mensalidade",
                      sector: "Financeiro",
                      payment: "Boleto bancário",
                      dueDate: "9/23/16",
                    },
                  ].map((row, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-4">{row.name}</td>
                      <td className="p-4">{row.value}</td>
                      <td className="p-4">{row.description}</td>
                      <td className="p-4">{row.sector}</td>
                      <td className="p-4">{row.payment}</td>
                      <td className="p-4">{row.dueDate}</td>
                      <td className="p-4">
                        <Button variant="ghost" size="icon">
                          <span className="material-symbols-rounded">
                            more_vert
                          </span>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex flex-col sm:flex-row items-center justify-between px-4 py-4 border-t gap-4">
                <p className="text-sm text-muted-foreground">
                  Mostrando 1 to 20 of 97 resultados
                </p>
                <div className="flex items-center gap-2">
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      chevron_left
                    </span>
                  </Button>
                  {[1, 2, 3, 4, 5, 6].map((page) => (
                    <Button
                      key={page}
                      variant={page === 1 ? "secondary" : "ghost"}
                      size="icon"
                      className="w-8 h-8"
                    >
                      {String(page).padStart(2, "0")}
                    </Button>
                  ))}
                  <Button variant="outline" size="icon">
                    <span className="material-symbols-rounded">
                      chevron_right
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
